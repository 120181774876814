.footer-container {
    background-color: rgba(114, 115, 170, 0.3);
    // padding-top: 40px;
    color: black;
}
  
.footer-content {
    max-width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
}
  
.footer-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
}
  
.footer-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.footer-logo-img {
    width: 200px;
}

.footer-links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    flex-direction: column;
}

.link {
    color: black;
    padding-bottom: 4px;
    margin: 8px 16px;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    transition: color 300ms, border-color 300ms;
}

.link:hover {
    color: #154c79;
    border-color: #154c79;
}

.footer-contact-container {
    display: flex;
    flex-direction: column;
}

.footer-contact-address, .footer-contact-email, .footer-contact-phone {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.footer-contact-address img,
.footer-contact-email img,
.footer-contact-phone img {
    margin-right: 8px;
    width: 24px; /* Adjust size if needed */
    height: 24px;
}

.footer-contact-address-text {
    display: flex;
    flex-direction: column;
}

.copyright-text {
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    color: #154c79;

    span {
        display: none;
    }
}

@media (max-width: 820px) {
    .footer-container {
        .footer-content{
            padding-bottom: 8px;
            
            .footer-row{
                flex-direction: column;
                align-items: center;

                .footer-logo-container {
                    margin-bottom: 16px;
                    p {
                        display: none;
                    }
                }

                .footer-links-container {
                    flex-direction: row;
                    margin-bottom: 16px;
                    font-size: 16px;
                }

                .footer-contact-container {
                    font-size: 12px;
                    img {
                        margin-right: 20px;
                    }
                }
            }

            .copyright-text {
                font-size: 12px;

                span {
                    display: inline;
                }
            }

        }
    }
}

@primary-color: #154C79;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;