.hero-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &.loading {
        background: black;
    }

    &.loaded {
        background: url('../../assets/images/multiline-landing-bg.jpg') center/cover no-repeat;
    }

    .opacity-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle at top left, rgba(255, 255, 255, 255), rgba(255, 255, 255, 1), transparent 70%);
        filter: blur(100px);
        z-index: 10;
        pointer-events: none; /* Ensures the overlay doesn't block interactions with underlying elements */
    }

    .hero-inner-container {
        position: relative;
        padding: 24px 32px;
        max-width: 1280px;
        margin: auto;
        z-index: 20;
        opacity: 0; /* Initial state */
        transform: translateY(120px); /* Initial state */
        transition: opacity 1s ease-out, transform 1s ease-out;
        display: flex;
        flex-direction: flex-start;
        width: 100%;

        .company-logo {
            display: none;
        }

        .left-column {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            padding-top: 96px;
            padding-bottom: 128px;
            margin-left: 0px;
            margin-right: 32px;
            margin-top: 72px;
        
            .heading {
                margin-top: 0px;
                color: black;
                font-size: 68px;
                font-weight: 500;
                text-align: left;
    
                .heading-bold {
                    // color: #154C79;
                    background: linear-gradient(90deg, rgba(114,115,170,1) 0%, rgba(21,76,121,1) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .notification {
                display: none;
                color: #ffffff;
                padding: 4px 12px;
                margin: 8px;
                border-left: 4px solid @secondary-color;
                font-size: 16px;
            }

            .sub-heading {
                // max-width: 40vw;
                text-align: justify;

                span {
                    background: linear-gradient(120deg, #19994866 0%, #19994866 100%);
                    background-repeat: no-repeat;
                    background-size: 100% 40%;
                    background-position: 0 90%;
                    // font-weight: bold;
                }
            }

            .primary-action {
                background-color: #154C79;
                padding: 8px 16px;
                font-size: 20px;
                border-radius: 4px;
                border-width: 0px;
                transition: background-color 0.3s, color 0.3s;
                cursor: pointer;
                width: fit-content;
                margin-top: 16px;
            
                .primary-action-link {
                    color: white;
                    text-decoration: none;
                }
            }
            
            .primary-action:hover {
                background-color: white;
                border: 1px solid #154C79;
            
                .primary-action-link {
                    color:  #154C79;
                }
            }
        }
    }

    .hero-inner-container.animate {
        opacity: 1;
        transform: translateY(0);
    }

    .company-logo {
        display: none;
        flex-direction: column;
        align-items: center;
    }
    
    .company-logo-hero {
        width: 180px;
    }
}

.arrow-container {
    display: none;
}

@media (max-width: 1024px) {
    .hero-container {
        min-height: 50vh;
    }
}

@media (max-width: 820px) {
    .hero-container {
        min-height: 100vh;

        .opacity-overlay {
            background: radial-gradient(circle at top left, rgba(255, 255, 255, 255), rgba(255, 255, 255, 1), transparent 90%);
        }

        .hero-inner-container {
            display: flex;
            flex-direction: column;

            .arrow-container {
                position: absolute;
                left: 50%; /* Center horizontally */
                bottom: -50px; /* Move it slightly up from the bottom */
                display: block; /* Show the arrow */
                width: 25px;
                animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
                cursor: pointer;
                height: 20px;
            }

            .arrow-down {
                height: 6px;
                background: @primary-color;
                transform: rotate(45deg);
                transform-origin: 0% 0%;
                border-radius: 5px;
            }
            
            .arrow-down:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 100%;
                border-radius: 5px;
                background: @primary-color;
                transform: rotate(-90deg);
                transform-origin: 0% 100%;
            }

            .company-logo {
                display: block;
            }

            .left-column {
                margin-left: 0px;
                margin-right: 0px;
                padding-top: 0px;
                padding-bottom: 64px;
                margin-top: 20px;

                .heading {
                    font-size: 40px;
                    margin-bottom: 12px;
                }

                .notification {
                    display: block;
                    color: black;
                    padding: 4px 12px;
                    margin: 8px;
                    border-left: 4px solid @secondary-color;
                    font-size: 16px;
                }

                .primary-action {
                    padding: 8px 16px;
                    font-size: 16px;
                    margin-top: 16px;
                }
            }

            // .sub-heading {
            //     display: none;
            // }
        }
    }
}
              
@keyframes bounce {
    50% {
        transform: translateY(-15px);
    }
}
@primary-color: #154C79;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;